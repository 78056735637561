@import '../../styles/includes';

.DownloadLinkList {
    $root: &;

    display: flex;
    justify-content: center;
    align-items: center;
    @extend %container;

    &__Container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 1046px;
        width: 100%;
    }

    &__Title {
        @extend %h2;
        margin-bottom: 24px;
    }

    &__Wrapper {
        -webkit-backdrop-filter: blur(50px);
        backdrop-filter: blur(50px);
        box-shadow: 0 4px 24px -1px rgba(0, 0, 0, 0.2);
        max-width: 1046px;
        margin-bottom: 32px;
        width: 100%;

        @include media(s) {
            margin-bottom: 48px;
        }
    }

    &__TabContainer {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    &__Tab {
        width: 100%;
        height: 100%;
        font-size: 2rem;
        background-color: white;
        color: black;
        border: none;
        border-bottom: 0.5px solid $colorRed50;
        overflow: hidden;
        cursor: pointer;

        &--Active {
            background-color: $colorRed50;
            color: white;
        }
    }

    &__TabTitle {
        font-family: $fontLight;
        font-size: 1.6rem;
        text-align: center;
        padding: 8px 32px;
        width: 100%;

        @include media(s) {
            font-size: 2rem;
            padding: 24px 30px;
        }
    }

    &__ListContainer {
        width: 100%;
    }

    &__List {
        width: 100%;
        padding: 34px 24px;

        @include media(m) {
            padding: 48px 52px;
        }

        @include media(ml) {
            padding: 48px 88px;
            columns: 2;
            column-gap: 72px;
        }
    }

    &__Item {
        margin-bottom: 24px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__Link {
        font-size: 1.6rem;
        display: inline;
        align-items: center;
        text-underline-offset: 4px;
        text-decoration-color: transparent;
        text-decoration-line: underline;
        text-decoration-thickness: 1px;

        @include media(m) {
            font-size: 2rem;
        }

        @media (hover: hover) {
            will-change: color, text-decoration-color;
            transition: color 0.25s ease, text-decoration-color 0.25s ease;

            &:hover,
            &:hover i {
                color: $colorRed50;
                text-decoration-color: $colorRed50;
            }
        }
        p {
            display: inline;
        }

        b {
            font-family: $fontBold;
        }

        i {
            color: $colorGray60;
        }
    }

    &__LinkIcon {
        display: block;
        margin-right: 8px;

        svg path {
            will-change: fill;
            transition: fill 0.25s ease;

            @media (hover: hover) {
                #{$root}__Link:hover & {
                    fill: $colorRed50;
                }
            }
        }
    }

    &__Buttons {
        display: flex;
        column-gap: 32px;
    }
}
